<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <v-container>

        <h1>
          <span>Тендеры</span>
          <div>
            <v-btn @click="$router.push('/tenderEdit')">
              Добавить тендер
            </v-btn>
          </div>
        </h1>

        <Register :config="regConfig" ref="registerComponent"></Register>


      </v-container>
    </transition>
  </div>
</template>


<script>
import {tendersRegistersConfig} from '../register/RegisterConfigs';

import Register from '../register/Register.vue';

export default {
  name: "TendersComponent",
  props: ['setSection'],
  components: {Register},
  data() {
    return {
      regConfig: tendersRegistersConfig
    };
  },
  beforeMount() {
    this.setSection('TENDERS')
  },
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>
